import Layout from '../components/Layout';
import MaxWidth from '../components/MaxWidth';
import SiteSearch from '../components/SiteSearch';
import {BREAKPOINT} from '../lib/styles';
import Image from 'next/image';
import styled from 'styled-components';
import {useContext} from 'react';
import AppContext from '../components/AppContext';
import Button from 'aac-components/components/Button';
import Link from 'next/link';
import CallrailLink from '../components/CallrailLink';

const ErrorPage = () => {
    const {callTrackingNumber} = useContext(AppContext);

    return (
        <Layout>
            <div className="hero">
                <MaxWidth>
                    <div className="hero__inner">
                        <div className="hero__image">
                            <Image
                                src="/static/404-page/broken-link.png"
                                fill
                                alt="Error Page Icon"
                                style={{objectFit: 'contain'}}
                                sizes="200px"
                                priority={true}
                            />
                        </div>
                        <div className="hero__copy">
                            <h1>Sorry, the page you requested could not be found.</h1>
                            <p>
                                The page may have been removed or the link you clicked may
                                have been broken.{' '}
                            </p>
                            <div>
                                <SiteSearchStyles>
                                    <SiteSearch iconColor="#fff" />
                                </SiteSearchStyles>
                            </div>
                        </div>
                    </div>
                </MaxWidth>
            </div>
            <MaxWidth>
                <div className="content">
                    <p>
                        Here are a couple of resources that we thought could help you find
                        what you're looking for.
                    </p>
                    <div className="content__cards">
                        <div className="content__card">
                            <div className="content__card--title">Ask a navigator</div>
                            <p>
                                We are a text away and have all the answers! Sign up for
                                text support to ask a navigator.{' '}
                            </p>
                            <ButtonStyles>
                                <Button href="/not-ready-to-talk">
                                    Sign up for text support
                                </Button>
                            </ButtonStyles>
                        </div>
                        <div className="content__card">
                            <div className="content__card--title">
                                Links you may find helpful
                            </div>
                            <div>
                                <Link
                                    href="/"
                                    title="Back to Home Page"
                                    style={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    Back to Home Page
                                </Link>
                                <div style={{marginBottom: '16px'}}>
                                    <CallrailLink
                                        style={{
                                            textDecoration: 'none',
                                        }}>
                                        24/7 Helpline: {callTrackingNumber?.display}
                                    </CallrailLink>
                                </div>
                                <Link
                                    href="/treatment-centers"
                                    title="Rehab Centers Near Me"
                                    style={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    Rehab Centers Near Me
                                </Link>
                                <Link
                                    href="/admissions"
                                    title="Admissions Process"
                                    style={{
                                        display: 'block',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    Admissions Process
                                </Link>
                            </div>
                        </div>
                        <div className="content__card">
                            <div className="content__card--title">
                                Frequently asked questions
                            </div>
                            <div>
                                <Link
                                    href="/rehab-guide/how-long-rehab-last"
                                    title="How long will rehab last?"
                                    style={{
                                        display: 'block',
                                        color: 'var(--secondary-300)',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    How long will rehab last?
                                </Link>
                                <Link
                                    href="/verify-insurance"
                                    title="Does my insurance cover rehab?"
                                    style={{
                                        display: 'block',
                                        color: 'var(--secondary-300)',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    Does my insurance cover rehab?
                                </Link>
                                <Link
                                    href="/payment-options"
                                    title="How can I pay for treatment?"
                                    style={{
                                        display: 'block',
                                        color: 'var(--secondary-300)',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    How can I pay for treatment?
                                </Link>
                                <Link
                                    href="/admissions/helpline"
                                    title="What can I expect when contacting AAC?"
                                    style={{
                                        display: 'block',
                                        color: 'var(--secondary-300)',
                                        textDecoration: 'none',
                                        marginBottom: '16px',
                                    }}>
                                    What can I expect when contacting AAC?
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </MaxWidth>
            <style jsx>
                {`
                    h1 {
                        color: var(--primary-400);
                        max-width: 600px;
                        margin-top: 0;
                    }
                    .hero {
                        background: linear-gradient(
                            264.86deg,
                            var(--secondary-100) 0%,
                            var(--tertiary-100) 100%
                        );
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero {
                            padding: 80px 0;
                            grid-template-columns: 1fr 2fr;
                            grid-gap: 30px;
                        }
                    }
                    .hero__inner {
                        display: grid;
                        grid-template-columns: 1fr;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .hero__inner {
                            grid-template-columns: auto auto;
                            justify-content: center;
                            grid-gap: 100px;
                        }
                    }
                    .hero__image {
                        position: relative;
                        width: 200px;
                        height: 260px;
                        margin: 0 auto 24px auto;
                    }
                    .hero p {
                        max-width: 500px;
                        margin-bottom: 40px;
                    }
                    .content {
                        padding: 40px 15px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .content {
                            padding: 40px 0 80px 0;
                        }
                    }
                    .content p {
                        text-align: center;
                        margin: 0 auto;
                        margin: 0 auto 40px auto;
                        max-width: 570px;
                    }
                    .content__cards {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 24px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .content__cards {
                            grid-template-columns: repeat(3, 1fr);
                            grid-gap: 32px;
                        }
                    }
                    .content__card {
                        padding: 40px;
                        border-radius: 8px;
                        text-align: left;
                    }
                    .content__card:nth-child(1) {
                        background: var(--primary-100);
                        border: 1px solid var(--primary-200);
                    }
                    .content__card:nth-child(2) {
                        background: var(--interactive-100);
                        border: 1px solid var(--interactive-200);
                    }
                    .content__card:nth-child(3) {
                        background: var(--secondary-100);
                        border: 1px solid var(--secondary-200);
                    }
                    .content__card--title {
                        font-weight: bold;
                        margin-bottom: 24px;
                    }
                    .content__card p {
                        text-align: left;
                        margin-bottom: 16px;
                    }
                    .content__card a {
                        display: block;
                        margin-bottom: 16px;
                        text-decoration: none;
                    }
                    .content__card:nth-child(3) a {
                        color: var(--secondary-300);
                    }
                `}
            </style>
        </Layout>
    );
};
export default ErrorPage;

const SiteSearchStyles = styled.div`
    button {
        background-color: var(--secondary-300);
        margin: 10px;
        padding: 8px;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        button {
            margin: 0;
            padding: 12px;
        }
    }
    button:hover {
        background-color: var(--secondary-400);
    }
`;
const ButtonStyles = styled.div`
    a {
        background: var(--primary-300);
        width: 100%;
        font-weight: normal;
        margin-bottom: 0;
    }
    a:hover {
        background: var(--primary-400);
    }
`;
